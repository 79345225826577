import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentService } from "src/app/core/services/environment.service";
import { API_URL } from "src/environments/settings";

@Injectable({
	providedIn: "root"
})
export class UsersPermissionsService {
	
	private apiUrl: string;
	
	constructor(
		private http: HttpClient,
		private env: EnvironmentService
	) {
		this.apiUrl = this.env.getValueTransferState(API_URL);
	}

	getUsers(SkipCount: number, MaxResultCount: number, SearchQ: string, unionId?: number) {
		let params: HttpParams = new HttpParams({
			fromObject: {
				SearchQ,
				MaxResultCount,
				SkipCount
			}
		});

		if (unionId) {
			params = params.append('unionId', unionId);
		}
		
		return this.http.get<any>(`${this.apiUrl}/app/permissionAdministrator/users`, { params });
	}

	getUserWithPermissions(id: string) {
		return this.http.get<any>(`${this.apiUrl}/app/permissionAdministrator/${id}`);
	}

	saveUserPermissions(id: string, permissions: any) {
		return this.http.put<any>(`${this.apiUrl}/app/permissionAdministrator/${id}`, permissions);
	}

	getRoles() {
		return this.http.get<any>(`${this.apiUrl}/app/permissionAdministrator/roles`);
	}

	saveUserRoles(id: string, roles: any) {
		return this.http.put<any>(`${this.apiUrl}/app/permissionAdministrator/userRoles`, {
			userId: id,
			roles: roles
		});
	}

	updateUser(payload: any) {
		return this.http.put<any>(`${this.apiUrl}/app/permissionAdministrator/user`, payload);
	}

	getTenantByDomain(domain: string) {
		return this.http.get<any>(`${this.apiUrl}/app/appTenantPublic/tenant?domain=${domain}`);
	}

	getUseMuster() {
		return this.http.get<any>(`${this.apiUrl}/app/appTenantPublic/useMuster`);
	}

	createUser(user: any) {
		return this.http.post<any>(`${this.apiUrl}/app/permissionAdministrator/user`, user);
	}

	deleteUser(id: string) {
		return this.http.delete<any>(`${this.apiUrl}/app/permissionAdministrator/${id}/user`);
	}
}
